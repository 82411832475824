import React from 'react';
import MainLayout from '../../layouts/main';
import { Link } from 'gatsby';
import { Typography } from '@material-ui/core';
import Section, { Label } from '../../components/Section';
import { SEO } from '../../components/SEO';
import { SignupButtonWithChoice } from '../../components/SignupButton';
import PageHead from '../../components/PageHead';
import {
  FeatureGrid,
  LinkManagementTracking,
  LinkManagementSingleLibrary,
  LinkManagementRevenue,
  LinkManagementAmazon,
  LinkManagementHeatmaps,
  LinkManagementPerPage,
  ContentAnalyticsImpressions,
  ContentAnalyticsRevisions,
  LinkManagementExport
} from '../../components/FeatureGrid';

export default () => {
  return (
    <MainLayout>
      <div>
        <SEO
          pathname="/product/affiliate-link-management/"
          title="Automate and Scale Your Affiliate Link Management"
          description="Stop managing affiliate links by hand, and let Affilimate find and track the thousands of links spread across your site in a matter of minutes."
        />
        <PageHead>
          <Typography
            variant="h3"
            gutterBottom
            component="h1"
            style={{
              maxWidth: '600px',
              margin: '0 auto 36px',
              fontWeight: 900
            }}
          >
            Automate and scale your affiliate link management
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ maxWidth: '510px', margin: '24px auto 12px' }}
          >
            Start tracking the thousands of existing affiliate links on your
            website in a matter of minutes, regardless of whether your links are
            cloaked or shortened.
            <br />
            <br />
            Then, attribute revenue to each link on your site by flipping a
            switch so you can{' '}
            <strong>learn the true value of every link</strong>.
          </Typography>
          <img
            src="/images/features/affiliate-link-management.png"
            style={{
              width: '80%',
              maxWidth: '700px',
              borderRadius: '8px',
              boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
              transform: 'rotate(-2deg)',
              margin: '48px auto',
              display: 'block'
            }}
            alt="Affiliate link management"
          />
          <div style={{ textAlign: 'center', margin: '48px auto 0' }}>
            <SignupButtonWithChoice text="Get started" />
          </div>
        </PageHead>
        <div style={{ maxWidth: '900px', margin: '96px auto 64px' }}>
          <Typography
            variant="h5"
            gutterBottom
            component="h2"
            style={{
              textAlign: 'center',
              fontWeight: 700
            }}
          >
            You need a system. Let's make it easy.
          </Typography>
          <Typography
            variant="body1"
            component="p"
            style={{
              maxWidth: '510px',
              margin: '0 auto',
              textAlign: 'center'
            }}
          >
            Say goodbye to tedious and manual affiliate link management.
          </Typography>
        </div>
        <FeatureGrid>
          <LinkManagementTracking />
          <LinkManagementRevenue />
          <LinkManagementAmazon />
          <LinkManagementPerPage />
          <LinkManagementSingleLibrary />
          <LinkManagementHeatmaps />
          <ContentAnalyticsImpressions />
          <ContentAnalyticsRevisions />
          <LinkManagementExport />
        </FeatureGrid>
        <div style={{ maxWidth: '900px', margin: '96px auto 48px' }}>
          <Typography
            variant="h5"
            gutterBottom
            component="h2"
            style={{
              textAlign: 'center',
              fontWeight: 700
            }}
          >
            Let your affiliate link management be automated
          </Typography>
          <Typography
            variant="body1"
            component="p"
            style={{
              maxWidth: '510px',
              margin: '0 auto',
              textAlign: 'center'
            }}
          >
            Here's how to get started in a few simple steps
          </Typography>
        </div>
        <Section
          image="/images/features/add-new-website.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Step 1 of 3</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                Add Affilimate's JavaScript snippet
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                Affilimate's snippets will track link clicks and impressions,
                and add attribution data to your affiliate links.
              </Typography>
              <Typography paragraph variant="body1">
                To get started, install the snippet and verify your website.
              </Typography>
            </>
          }
        />
        <Section
          image="/images/features/start-link-crawl.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Step 2 of 3</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                Crawl your site for affiliate links using Affilimate's site scan
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                Affilimate's site scan will discover all the affiliate links on
                your website using our link detection algorithm.
              </Typography>
              <Typography paragraph variant="body1">
                No matter if your links are cloaked, shortened, or just standard
                affiliate links.
              </Typography>
            </>
          }
        />
        <Section
          image="/images/features/link-scan-result.png"
          variant="screenshot"
          orientation="right"
          headline={
            <>
              <Label>Step 3 of 3</Label>
              <Typography variant="h5" component="h3" gutterBottom>
                Review the recommended affiliate links and start tracking
              </Typography>
            </>
          }
          body={
            <>
              <Typography paragraph variant="body1">
                Click the button to import your links and Affilimate will begin
                tracking them.
              </Typography>
              <Typography paragraph variant="body1">
                From here, you can enable{' '}
                <Link to="/revenue-attribution/">revenue attribution</Link> or
                set up the{' '}
                <a
                  href="https://affilimate.com/docs/find-and-replace-broken-amazon-links/"
                  target="_blank"
                >
                  Amazon link checker
                </a>
                .
              </Typography>
            </>
          }
        />
        <div style={{ textAlign: 'center', margin: '48px auto 96px' }}>
          <Typography
            variant="h6"
            component="p"
            style={{ fontWeight: 'bold' }}
            gutterBottom
          >
            The sooner you start, the more data you'll have
          </Typography>
          <Typography
            variant="body1"
            component="p"
            style={{
              maxWidth: '510px',
              margin: '0 auto 24px',
              textAlign: 'center'
            }}
          >
            Your analytics become more valuable the longer you've been tracking.
            Get started by importing your affiliate links today.
          </Typography>
          <SignupButtonWithChoice text="Start tracking your links" />
        </div>
      </div>
    </MainLayout>
  );
};
